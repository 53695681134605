import React from "react"
import { graphql } from "gatsby"

import CursorProvider from "context/cursor"

import Layout from "components/Layout"

import {
  Hero,
  Discover,
  Entertainment,
  Quality,
  Talk,
  Banner,
  Location,
  SliderLocation,
  Interior,
  About,
  Contact,
  CallToAction,
  Gallery,
  Nav,
} from "page_components/sky-landing"

import { Apartments } from "page_components/investment"

const Investment = ({ data, location }) => {
  const investment = "Sky Trust II"
  const investmentRaw = "sky_trust_ii"
  const allApartments = data.allWpApartmentSkyTrust2.nodes

  return (
    <CursorProvider>
      <Layout
        location={location}
        rmGlobalPopup
        mainClass="sky-landing"
        seo={{
          title: investment,
        }}
      >
        <Nav />
        <Hero />
        <Discover />
        <Apartments
          apartmentList={allApartments}
          investment={investment}
          investmentRaw={investmentRaw}
        />
        <Entertainment />
        <Quality />
        <Talk />
        <Banner />
        <Location />
        <SliderLocation />
        <Interior />
        <Gallery />
        <About />
        <Contact />
        <CallToAction />
      </Layout>
    </CursorProvider>
  )
}

export const query = graphql`
  query {
    allWpApartmentSkyTrust2(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          d3EstateUrl
          type
          apartmentNumber
          investmentBuilding
          buildingNumber
          area
          floor
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
          placesList {
            area
            name
          }
        }
      }
    }
  }
`

export default Investment
